import Vue from 'vue';
import VueRouter from 'vue-router';

import { isUserLoggedIn } from '@/auth/utils';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: '/',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      redirect: `/:prefix/dashboard`,
      name: 'home'
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('@/views/pages/logout/logout.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'basic'
      }
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'basic'
      }
    },
    {
      path: '/:prefix/not-authorized',
      name: 'not-authorized',
      component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'basic'
      }
    },
    {
      path: '/:prefix/authenticate-required',
      name: 'authenticate-required',
      component: () => import('@/views/pages/miscellaneous/AuthenticateRequired.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'basic'
      }
    },
    {
      path: '/:prefix/system-setting/resource-status',
      name: 'resource-status',
      component: () => import('@/views/pages/system-setting/ResourceStatus.vue'),
      meta: {
        resource: 'pcp',
        action: 'read',
        breadcrumb: [
          {
            text: 'Classification',
            active: false,
            icon: 'settings'
          },
          {
            text: 'Resource Status',
            active: true
          }
        ]
      }
    },
    {
      path: '/:prefix/system-setting/refuse-status',
      name: 'refuse-status',
      component: () => import('@/views/pages/system-setting/RefuseStatus.vue'),
      meta: {
        resource: 'pcp',
        action: 'read',
        breadcrumb: [
          {
            text: 'Classification',
            active: false,
            icon: 'settings'
          },
          {
            text: 'Rejection Reason',
            active: true
          }
        ]
      }
    },
    {
      path: '/:prefix/system-setting/sites',
      name: 'sites',
      component: () => import('@/views/pages/system-setting/Sites.vue'),
      meta: {
        resource: 'pcp',
        action: 'read',
        breadcrumb: [
          {
            text: 'Sites',
            active: true,
            icon: 'settings'
          }
        ]
      }
    },
    {
      path: '/:prefix/system-setting/resources',
      name: 'resources',
      component: () => import('@/views/pages/system-setting/Resources.vue'),
      meta: {
        resource: 'pcp',
        action: 'read',
        breadcrumb: [
          {
            text: 'Primary Resources',
            active: true,
            icon: 'settings'
          }
        ]
      }
    },
    {
      path: '/:prefix/system-setting/workcenters',
      name: 'workcenters',
      component: () => import('@/views/pages/system-setting/Workcenters.vue'),
      meta: {
        resource: 'pcp',
        action: 'read',
        breadcrumb: [
          {
            text: 'Workcenters',
            active: true,
            icon: 'settings'
          }
        ]
      }
    },
    {
      path: '/:prefix/system-setting/params',
      name: 'params',
      component: () => import('@/views/pages/system-setting/Params.vue'),
      meta: {
        resource: 'pcp',
        action: 'read'
      }
    },
    {
      path: '/:prefix/integration/data-source',
      name: 'data-source',
      component: () => import('@/views/pages/integration/data-source/DataSource.vue'),
      meta: {
        resource: 'integration',
        action: 'read',
        breadcrumb: [
          {
            text: 'Data Source',
            active: true,
            icon: 'integration'
          }
        ]
      }
    },
    {
      path: '/:prefix/integration/pipelines',
      name: 'pipelines',
      component: () => import('@/views/pages/integration/pipelines/Pipelines.vue'),
      meta: {
        resource: 'integration',
        action: 'read',
        breadcrumb: [
          {
            text: 'Pipelines',
            active: true,
            icon: 'integration'
          }
        ]
      }
    },
    {
      path: '/:prefix/integration/new-pipeline/:action?/:id?',
      name: 'new-pipeline',
      component: () => import('@/views/pages/integration/pipelines/NewPipeline.vue'),
      meta: {
        resource: 'integration',
        action: 'read',
        breadcrumb: [
          {
            text: 'Pipelines',
            active: false,
            to: { name: 'pipelines' },
            icon: 'integration'
          },
          {
            text: 'IntegrationPage.NewPipeline',
            active: true
          }
        ]
      }
    },
    {
      path: '/:prefix/integration/object/:connection_id/:type',
      name: 'object',
      component: () => import('@/views/pages/integration/objects/Object.vue'),
      meta: {
        resource: 'integration',
        action: 'read',
        breadcrumb: [
          {
            text: 'Data Source',
            to: { name: 'data-source' },
            icon: 'integration'
          },
          {
            text: 'Object',
            active: true
          }
        ]
      }
    },
    {
      path: '/:prefix/integration/log-center',
      name: 'log-center',
      component: () => import('@/views/pages/integration/log-center/LogCenter.vue'),
      meta: {
        resource: 'integration',
        action: 'read',
        breadcrumb: [
          {
            text: 'Log Center',
            active: true,
            icon: 'integration'
          }
        ]
      }
    },
    {
      path: '/:prefix/integration/notification/download-client',
      name: 'download-client',
      component: () => import('@/views/DownloadClient.vue'),
      meta: {
        resource: 'integration',
        action: 'read',
        breadcrumb: [
          {
            text: 'Download',
            active: true,
            icon: 'integration'
          }
        ]
      }
    },
    {
      path: '*',
      redirect: 'error-404'
    },
    {
      path: '/:prefix/dashboard',
      name: 'my-connect-dashboard',
      component: () => import('@/views/pages/my-connect/Dashboard.vue'),
      meta: {
        breadcrumb: [
          {
            text: 'Breadcrumbs.Dashboard',
            active: true,
            icon: 'dashboard'
          }
        ]
      }
    }
  ]
});

router.beforeEach((to, _, next) => {
  const { name, path } = to;
  const isLoggedIn = isUserLoggedIn();
  const authenticationPages = ['login', 'forgot'];
  let authenticationPage = '';

  const isAuthenticationPage = authenticationPages.some((page) => {
    if (path.includes(page)) {
      authenticationPage = page;
      return true;
    }
  });

  if (name === 'home' && isAuthenticationPage) return next({ name: authenticationPage });

  if (!name) return next({ name: 'home' });
  if (isLoggedIn && isAuthenticationPage) return next({ name: 'home' });

  return next();
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});

export default router;
